.card-box {
  background    : rgba(255, 212, 152, 0.2);
  border        : solid 2px #dedede; margin: 32px; padding: 16px; display: flex; border-radius: 4px;
}

.cc-placeholder {
  display       : flex;
  margin        : 32px;
  padding       : 16px;

  background    : rgba(222, 222, 222, 0.2);
  border        : solid 2px #dedede;
  border-radius : 4px;
  align-items   : center;
}

.pill {
  background    : #dedede;
  padding       : 4px;
  border-radius : 4px;
  font-weight   : bold;
  margin-right  : 8px;
  font-size     : 12px;
}

.pill--green {
  background  : rgba(107, 190, 111, 0.2);
}

.pill--red {
  background  : rgba(217, 28, 92, 0.2);
  color       : #fff;
}


.bg-red {
  background  : rgba(217, 28, 92, 0.2);
  border      : solid rgba(217, 28, 92, 0.2) 2px!important;
}

.bg-green {
  background  : rgba(107, 190, 111, 0.2)!important;
  border      : solid rgba(107, 190, 111, 0.2) 2px!important;
}
