.panel {
  background    : rgb(255, 236, 200);
  padding       : 16px;
  border        : solid 1px #dedede;
  border-radius : 4px;
}


.no-data-found {
  text-align  : center;
  margin      : auto;
  width       : 550px;
  margin-top  : 128px;
}
