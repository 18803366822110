@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "font-awesome/css/font-awesome.css";
@import "primeicons/primeicons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import "./assets/style/variables.scss";

// System style - generic style configuration used throw out the whole app
@import "./assets/style/system/margin-padding.scss";
@import "./assets/style/system/panels.scss";
@import "./assets/style/system/font-text.scss";
@import "./assets/style/system/buttons.scss";
@import "./assets/style/system/layout.scss";

// Overwrites - of thirt party libraries
@import "./assets/style/overwrites/primeng.overwrites.scss";

// Specific component style
@import "./assets/style/components/cc-box.component.scss";

* {
  font-family: "Inter", sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {

  padding               : 0;
  margin                : 0;
  line-height           : 1.5;
  -webkit-font-smoothing: antialiased;
  background            : #F8FAFC!important;
  font-family           : 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

input, button, textarea, select {
  font: inherit;
}

.web-content {
  padding : 32px;
  margin  : auto;
}

h1 {
  font-size   : 26px;
  font-weight : bold;
  cursor      : pointer;
}

h2 {
  font-size   : 20px;
  font-weight : bold;
  cursor      : pointer;
}


.warning {
  background: rgba(240, 219, 79, 0.7);

  padding: 16px;
  font-size: 22px;
  border: solid #dedede 1px;
}

.input          {
  margin-top    : 4px;
  width         : 100%;
  border        : solid #dedede 1px;
  border-radius : 5px;
  padding       : 8px 16px;
}

.input-icon {
  position      : absolute;
  right         : 0px;
  top           : 4px;
  background    : rgb(255, 236, 200);
  padding       : 4px;
  padding       : 9px 8px;
  border-radius : 4px;
  cursor        : pointer;
}


.is-invalid     {
  border        : solid #ff0000 1px!important;
}

.is-primary {
  border      : solid #00ff00 1px;
  background  : #c1ef5c;
  color       : #1a1a1a;
}


.panel          {
  padding       : 16px;
  border-radius : 4px;
  background    : rgb(255, 236, 200);
  border        : solid 1px #dedede;
}

.no-data-found {
  text-align  : center;
  margin      : auto;
  width       : 550px;
  margin-top  : 128px;
}
