.flex {
  display         : flex;
  justify-content : space-between;
}

.just-flex {
  display         : flex;
}

.col  { flex    : 1!important; }
.col2 { flex    : 2!important; }
.col3 { flex    : 3!important; }
.col4 { flex    : 4!important; }
.col5 { flex    : 5!important; }
.col6 { flex    : 6!important; }



.bg-border {
  background-color: #fff; border: solid #dedede 1px;
}



.layout--col {
  display         : flex;
  flex-direction  : column;
  justify-content : space-between;
  height          : 100%;
}
