.fs12 { font-size: 12px;      }
.fs16 { font-size: 16px;      }
.fs18 { font-size: 18px;      }
.fs24 { font-size: 24px;      }
.fs32 { font-size: 32px;      }
.fs46 { font-size: 46px;      }

.fsb12 { font-size: 12px; font-weight: bold;  }
.fsb16 { font-size: 16px; font-weight: bold;  }
.fsb18 { font-size: 18px; font-weight: bold;  }
.fsb24 { font-size: 24px; font-weight: bold;  }
.fsb32 { font-size: 32px; font-weight: bold;  }
.fsb46 { font-size: 46px; font-weight: bold;  }

.tac { text-align: center;  }
.tal { text-align: left;    }
.tar { text-align: right;   }

.h1 { font-size: 20px; font-weight: bold; }
.h2 { font-size: 18px; font-weight: bold; }
.h3 { font-size: 16px; font-weight: bold; text-transform: uppercase; }
.h4 { font-size: 14px; font-weight: bold; }

.c-success   { color      : #28b02f; }
.c-fail      { color      : #d60b52; }
.c-blue      { color      : #2196f3; }
.c-lightblue { color      : #bab7ff; }
.c-orange    { color      : #fdc576; }
.c-purple    { color      : #dca3b7; }
.c-pink      { color      : #f99a9a; }

.bc-success  { background : #28b02f; }
.bc-fail     { background : #d60b52; }
.bc-blue     { color      : #2196f3; }
.bc-lightblue{ background : #bab7ff; }
.bc-orange   { background : #fdc576; }
.bc-purple   { background : #dca3b7; }
.bc-pink     { background : #f99a9a; }
