.p-dropdown     {
  margin-top    : 4px;
  width         : 100%;
  height        : 42px;
}

.p-calendar     {
  margin-top    : 4px;
  width         : 100%;
  height        : 42px;
}

.p-togglebutton {
  width   : 100%;
  height  : 41px!important;
}


.p-button {
  padding: 8px 16px;
}


.p-selectbutton {
  margin-top: -9px;
}
