
.btn {
  display       : inline-block;
  cursor        : pointer;
  border        : solid #dedede 1px;
  color         : #374151;
  border-radius : 4px;
  padding       : 8px;
  padding-right : 16px;
  padding-left  : 16px;

  i {
    margin-right  : 12px;
    margin-top    : 6px;
    margin-left   : 6px;
  }
}

.btn--wide {
  cursor      : pointer;
  width       : 100%;
  text-align  : center;
  padding     : 16px;
}

.btn-danger {
  background  : #f24147;
  border      : solid #f24147 1px;
  color       : #fff;
  font-weight : bold;
}

.btn-primary {
  background  : #226092;
  border      : solid #226092 1px;
  color       : #fff;
  font-weight : bold;
}

.btn-plane {
  cursor        : pointer;
  background    : #fff;
  font-weight   : bold;
  align-content : center;
  color         : #226091
}

.btn-disabled {
  cursor        : pointer;
  background    : #fff;
  font-weight   : bold;
  align-content : center;
  background    : #dedede;
  color         : #226091
}
