.m5   { margin  : 5px;  }
.m6   { margin  : 6px;  }
.m7   { margin  : 7px;  }
.m8   { margin  : 8px;  }
.m9   { margin  : 9px;  }
.m10  { margin  : 10px; }
.m14  { margin  : 14px; }
.m16  { margin  : 16px; }
.m20  { margin  : 20px; }
.m25  { margin  : 25px; }
.m32  { margin  : 32px; }
.m36  { margin  : 36px; }
.m64  { margin  : 64px; }

.mt5   { margin-top  : 5px;  }
.mt6   { margin-top  : 6px;  }
.mt7   { margin-top  : 7px;  }
.mt8   { margin-top  : 8px;  }
.mt9   { margin-top  : 9px;  }
.mt10  { margin-top  : 10px; }
.mt14  { margin-top  : 14px; }
.mt16  { margin-top  : 16px; }
.mt20  { margin-top  : 20px; }
.mt25  { margin-top  : 25px; }
.mt32  { margin-top  : 32px; }
.mt36  { margin-top  : 36px; }
.mt64  { margin-top  : 64px; }

.mb5   { margin-bottom  : 5px;  }
.mb6   { margin-bottom  : 6px;  }
.mb7   { margin-bottom  : 7px;  }
.mb8   { margin-bottom  : 8px;  }
.mb9   { margin-bottom  : 9px;  }
.mb10  { margin-bottom  : 10px; }
.mb14  { margin-bottom  : 14px; }
.mb16  { margin-bottom  : 16px; }
.mb20  { margin-bottom  : 20px; }
.mb25  { margin-bottom  : 25px; }
.mb32  { margin-bottom  : 32px; }
.mb36  { margin-bottom  : 36px; }
.mb64  { margin-bottom  : 64px; }


.ml8   { margin-left : 8px;  }
.ml16  { margin-left : 16px; }
.ml32  { margin-left : 32px; }

.mr5   { margin-right  : 5px;  }
.mr6   { margin-right  : 6px;  }
.mr7   { margin-right  : 7px;  }
.mr8   { margin-right  : 8px;  }
.mr9   { margin-right  : 9px;  }
.mr10  { margin-right  : 10px; }
.mr14  { margin-right  : 14px; }
.mr16  { margin-right  : 16px; }
.mr32  { margin-right  : 32px; }
.mr36  { margin-right  : 36px; }
.mr64  { margin-right  : 64px; }

.p5   { padding  : 5px;  }
.p6   { padding  : 6px;  }
.p7   { padding  : 7px;  }
.p8   { padding  : 8px;  }
.p9   { padding  : 9px;  }
.p10  { padding  : 10px; }
.p14  { padding  : 14px; }
.p16  { padding  : 16px; }
.p20  { padding  : 20px; }
.p25  { padding  : 25px; }
.p32  { padding  : 32px; }
.p64  { padding  : 64px; }


.pt5   { padding-top  : 5px;  }
.pt6   { padding-top  : 6px;  }
.pt7   { padding-top  : 7px;  }
.pt8   { padding-top  : 8px;  }
.pt9   { padding-top  : 9px;  }
.pt10  { padding-top  : 10px; }
.pt14  { padding-top  : 14px; }
.pt16  { padding-top  : 16px; }
.pt20  { padding-top  : 20px; }
.pt25  { padding-top  : 25px; }
.pt32  { padding-top  : 32px; }
.pt64  { padding-top  : 64px; }

.pb5   { padding-bottom  : 5px;  }
.pb6   { padding-bottom  : 6px;  }
.pb7   { padding-bottom  : 7px;  }
.pb8   { padding-bottom  : 8px;  }
.pb9   { padding-bottom  : 9px;  }
.pb10  { padding-bottom  : 10px; }
.pb14  { padding-bottom  : 14px; }
.pb16  { padding-bottom  : 16px; }
.pb20  { padding-bottom  : 20px; }
.pb25  { padding-bottom  : 25px; }
.pb32  { padding-bottom  : 32px; }
.pb64  { padding-bottom  : 64px; }


.pl8   { padding-left : 8px;  }
.pl16  { padding-left : 16px; }
.pl32  { padding-left : 32px; }

.pr5   { padding-right  : 5px;  }
.pr6   { padding-right  : 6px;  }
.pr7   { padding-right  : 7px;  }
.pr8   { padding-right  : 8px;  }
.pr9   { padding-right  : 9px;  }
.pr10  { padding-right  : 10px; }
.pr14  { padding-right  : 14px; }
.pr16  { padding-right  : 16px; }
.pr32  { padding-right  : 32px; }
.pr64  { padding-right  : 64px; }


.m-gap1 { margin-top: 8px; margin-bottom: 8px; }
.m-gap2 { margin-top: 16px; margin-bottom: 16px; }
